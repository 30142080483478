/**
* Generated automatically at built-time (2024-10-07T17:10:57.062Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "udekokken-rustfrit-stal",templateKey: "sites/103-8a004c22-853a-4d00-8dd5-becff92f78e4"};