/**
* Generated automatically at built-time (2024-10-07T17:10:57.095Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "udekokken-sort",templateKey: "sites/103-32073bf6-8e03-4518-825d-0ebd8666ab71"};